import React from "react";
import horizontal from "../images/video.mp4";

export default function VideoBackground() {
  return (
    <div>
      <section className="video-wrapper h-full w-full overflow-hidden cover object-cover">
        <video
          autoPlay
          playsInline
          muted
          loop
          id="video"
          className="object-cover max-h-auto inset-0 w-full h-full z-20 absolute "
        >
          <source src="https://olleburl.in/falafelandburgers/wp-content/uploads/2022/03/video.mp4" type="video/mp4" />
        </video>
      </section>
    </div>
  );
}
