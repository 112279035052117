/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";

import Header from "./header";
import Hero from "./Hero";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <>
      <div className="flex flex-col bg-gray-100 text-primary">
        <div className="flex flex-col md:px-10 h-screen md:h-screen-95 overflow-hidden">
          <div className="">
            <Header />
          </div>
          <div className="flex-grow items-center justify-center h-full flex flex-col">
            <Hero />
          </div>
        </div>

        <main className="flex-1 w-full pb-8 mx-auto">{children}</main>
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
