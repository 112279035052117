import React from "react"

export default function Footer() {
  return (
    <footer
      id="kontakt"
      className="bg-primary text-xs md:text-sm text-pink-500"
    >
      <div className="max-w-6xl mx-auto py-8 flex flex-col items-center space-y-2">
        <p>Malmö Saluhall - Gibraltargatan 6</p>
        <p>
          <a href="mailto:falafelburgare@gmail.com" className="underline">
            Epost
          </a>
          . Följ oss på{" "}
          <a
            className="underline"
            href="http://instagram.com/falafelandburgers/"
          >
            Instagram
          </a>{" "}
          /{" "}
          <a
            className="underline"
            href="https://www.facebook.com/falafelandburgers/"
          >
            Facebook
          </a>
        </p>
        <p className="">
          &copy; Falafel &amp; Burgers {new Date().getFullYear()}. Hemsida av{" "}
          <a href="https://www.olleburlin.se" className="underline">
            Olle Burlin
          </a>
        </p>
      </div>
    </footer>
  )
}
