import * as React from "react";
import PropTypes from "prop-types";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Headroom from "react-headroom";
const Header = () => (
  <Headroom>
    <header className="bg-gray-100 z-50 tracking-widest">
      <div className="flex items-center justify-center px-8 py-3 mx-auto ">
        <nav className="block items-center w-auto">
          {[
            {
              route: `/#hamburgare`,
              title: `Hamburgare`,
            },
            {
              route: `/#falafel`,
              title: `Falafel`,
            },

            {
              route: `/#kontakt`,
              title: `Kontakt`,
            },
          ].map(link => (
            <AnchorLink
              className="no-underline inline-block mt-0 px-2 md:px-3 marker text-sm md:text-md uppercase"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </AnchorLink>
          ))}
        </nav>
      </div>
    </header>
  </Headroom>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
