import React from "react"
// import { StaticImage } from "gatsby-plugin-image";
import Lottie from "react-lottie"
import * as animationData from "../images/data.json"
import VideoBackground from "./VideoBackground"

export default function Hero() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <div className="w-full flex flex-col h-full overflow-hidden md:px-10 relative justify-center items-center md:justify-start md:items-end">
      <div className=" w-3/4 md:w-1/3 lg:w-1/4 absolute z-10 md:p-4 md:mt-8">
        <Lottie options={defaultOptions} />
      </div>

      <div className="vimeo-wrapper ">
        <VideoBackground />
      </div>
    </div>
  )
}
